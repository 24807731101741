




























import Page from '@/components/swine/layout/Page.vue';
import InnerPageTemplate from '@/components/swine/layout/InnerPageTemplate.vue';
import ClientDetails from './summary/ClientDetails.vue';
import { ClientStores } from '@/store/swine/client/index';
import { Component, Vue, Watch } from 'vue-property-decorator';
import { Client } from '@/models/swine/client/client.model';

@Component({
  components: {
    Page,
    InnerPageTemplate,
    ClientDetails,
  },
})
export default class ClientSummary extends Vue {
  store = ClientStores.detail;
  isDetailShown = false;
  client: Client | null = null;

  get buttonText() {
    return this.isDetailShown ? 'Release to hide' : 'Hold to view';
  }

  mounted() {
    const species = (this.$router.currentRoute.fullPath.split('/'))[1]
    this.store.setSpecies(species)
    this.store.getClientDetails(this.$route.params.clientId);
  }

  @Watch('store.response')
  updateClientDetails() {
    if (this.store.response) {
      this.store.setClientDetails(this.store.response);
    }
  }

  @Watch('store.clientDetails')
  updateClientLoaded() {
    if (this.store.clientDetails) {
      this.client = this.store.clientDetails;
    }
  }

  editClient() {
    this.$router.push({
      name: 'EditClient',
      params: {
        clientId: this.$route.params.clientId,
      },
    });
  }
}
