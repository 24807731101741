













































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { DEFAULT_SELECT_OPTION } from '@/utils/constants';
import { Farm } from '@/models/swine/farm/farm.model';
import TableLayout from '@/components/swine/layout/TableLayout.vue';
import Dropdown from '@/components/swine/Dropdown.vue';
import SearchBar from '@/components/swine/SearchBar.vue';
import { DropdownOption } from '@/models/swine/dropdown.model';
import { generateUniqueId } from '@/utils/lodash';

@Component({
  components: {
    TableLayout,
    Dropdown,
    SearchBar,
  },
})
export default class ClientDetails extends Vue {
  @Prop({
    type: Array,
    default: null,
  })
  farm!: Farm[];

  @Prop({
    type: String,
    default: '',
  })
  clientCode!: string;

  @Prop({
    type: Boolean,
    default: false,
  })
  showDetails!: boolean;

  farmFilterOptions = DEFAULT_SELECT_OPTION;
  farmUnitFilterOptions = DEFAULT_SELECT_OPTION;

  filteredFarm: Farm[] = [];

  selectedFarmFilter = '';
  selectedFarmUnitFilter = '';

  mounted() {
    this.updateVisualDetails();
  }

  @Watch('farm')
  updateVisualDetails() {
    // We set the farm
    this.farm.forEach((data) => {
      if (data.name === null) {
        data.name = 'XXXXXXX';
      }
      return data;
    });
    this.filteredFarm = [...this.farm];

    // We set the filters
    const farmOptions = this.farm.map((farm) => {
      return {
        text: farm.farmCode,
        value: farm.farmCode,
        uniqueKey: generateUniqueId(farm.farmCode),
      };
    });
    this.farmFilterOptions = [...DEFAULT_SELECT_OPTION, ...farmOptions];
  }

  searchDetails(searchString: string) {
    if (searchString === '') {
      this.filteredFarm = [...this.farm];
    } else {
      // We want to make sure they can search by farm name, farm code & farm unit code
      this.filteredFarm = this.farm.filter((farm) => {
        const farmCode = `${this.clientCode}-${farm.farmCode}`;

        // This is going to be an array of string
        const farmUnitCodes = farm.farmUnits.map((farmUnit) => {
          return `${farmCode}-${farmUnit.farmUnitCode}`;
        });

        const hasResult = farmUnitCodes.some((code) =>
          code.includes(searchString),
        );

        return (
          farm.name.includes(searchString) ||
          farmCode.includes(searchString) ||
          hasResult
        );
      });
    }
  }

  changeFarmFilter(params: DropdownOption) {
    if (params && params.value) {
      this.selectedFarmFilter = params.value;
    }

    // Reset to default
    this.selectedFarmUnitFilter = '';
    this.filteredFarm = [...this.farm];
    this.farmUnitFilterOptions = [...DEFAULT_SELECT_OPTION];

    if (this.selectedFarmFilter !== '') {
      this.performFarmFiltering();
    }
  }

  performFarmFiltering() {
    this.filteredFarm = this.farm.filter(
      (farm) => farm.farmCode === this.selectedFarmFilter,
    );

    // Now get the farm unit filter options
    this.filteredFarm.map((farm) => {
      const options = farm.farmUnits.map((farmUnit) => {
        return {
          text: farmUnit.farmUnitCode,
          value: farmUnit.farmUnitCode,
          uniqueKey: generateUniqueId(farmUnit.farmUnitCode),
        };
      });
      this.farmUnitFilterOptions.push(...options);
    });
  }

  changeFarmUnitFilter(params: DropdownOption) {
    if (params && params.value) {
      this.selectedFarmUnitFilter = params.value;
    }

    // Reset
    this.filteredFarm = [...this.farm];
    this.farmUnitFilterOptions = [...DEFAULT_SELECT_OPTION];

    // Filter farms
    this.performFarmFiltering();

    // Now we can filter the farm units
    if (this.selectedFarmUnitFilter !== '') {
      this.filteredFarm = this.filteredFarm.map((farm) => {
        const farmUnit = farm.farmUnits.filter(
          (farmUnit) => farmUnit.farmUnitCode === this.selectedFarmUnitFilter,
        );
        return {
          ...farm,
          farmUnit,
        };
      });
    }
  }
}
