
























































import { Component, Prop, Vue } from 'vue-property-decorator';
import { Client } from '@/models/swine/client/client.model';
import ClientDetailsTable from './ClientDetailsTable.vue';

@Component({
  components: {
    ClientDetailsTable,
  },
})
export default class ClientDetails extends Vue {
  @Prop({
    type: Object,
    default: null,
  })
  client!: Client;

  @Prop({
    type: Boolean,
    default: false,
  })
  showDetails!: boolean;

  get streetAddress1() {
    if (this.client.addressLine1) {
      try {
        return this.client.addressLine1.split('\n')[0];
      } catch (err) {
        return '';
      }
    }
    return '';
  }

  get streetAddress2() {
    if (this.client.addressLine1) {
      try {
        return this.client.addressLine1.split('\n')[1];
      } catch (err) {
        return '';
      }
    }
    return '';
  }

  get farmCount() {
    if (this.client.farms) {
      return this.client.farms.length;
    }
    return 0;
  }

  get farmUnitCount() {
    if (this.client.farms) {
      const total = this.client.farms.reduce((sum, farm) => {
        return sum + farm.farmUnits.length;
      }, 0);
      return total;
    }
    return 0;
  }

  get auditReportCount() {
    if (this.client.farms) {
      const mappedResult = this.client.farms.map((farm) => {
        const total = farm.farmUnits.reduce((sum, farmUnit) => {
          return sum + farmUnit.auditReportCount;
        }, 0);
        return total;
      });
      return mappedResult.reduce((sum, val) => sum + val, 0);
    }
    return 0;
  }
}
